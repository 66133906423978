import React from 'react'
import './offerButton.css'
import { PopUp } from '../../Pages/LandingPage/PopUp'
import { Button, useDisclosure } from '@nextui-org/react'

const OfferButton = () => {
  const modalState = useDisclosure()
  const { onOpen } = modalState
  return (
    <div className="mobile-buttons">
      <Button
        variant="shadow"
        onClick={onOpen}
        className="mobile-button book-appointment"
      >
        Get in touch
      </Button>
      {onOpen && <PopUp modalState={modalState} title={'Get in touch'} />}
    </div>
  )
}

export default OfferButton
