import React from 'react'
import './aboutrepairs.css'
import { AboutRcheck } from '../../../Assets/Images/AboutRepairs'

const AboutRepairList = [
  'LEAK REPAIRS',
  'SIDING REPAIRS',
  'WINDOW REPAIRS',
  'STUCCO REMEDIATION',
]

const AboutRepairs = () => {
  return (
    <div className="aboutrepairs_block">
      <div className="customContainer aboutr_content">
        <div className="aboutr_content_left">
          <p className="aboutr_content_text">
            At XLeum, we understand the importance of a well-maintained home.
            Our skilled team specializes in a range of essential repair and
            service solutions to keep your property in top condition. Whether
            you’re dealing with leaks, damaged siding, problematic windows, or
            stucco issues, we’ve got you covered with expert craftsmanship and
            reliable service.
          </p>
          <div className="aboutr_checks_block">
            {AboutRepairList.map((item, index) => (
              <div key={index} className="aboutr_check_block">
                <img src={AboutRcheck} alt="AboutRcheck" />
                <p className="aboutr_check_text">{item}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="aboutr_content_right"></div>
      </div>
    </div>
  )
}

export default AboutRepairs
