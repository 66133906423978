import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalContent,
  Radio,
  RadioGroup,
} from '@nextui-org/react'
import styles from './PopUp.module.css'
import { Controller, useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import {
  closeBtn,
  iconMail,
  iconMark,
  iconName,
} from '../../../Assets/Icons/LandingPage'
import { PhoneInput } from 'react-international-phone'
import { Toastify } from '../../../Components/Index'
import React, { memo, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { toEmail, url } from '../../../Constants/const'

const PopUp = memo(({ modalState, title }) => {
  const { isOpen, onClose, onOpenChange } = modalState
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState(new Set())
  const [maxLength, setMaxLength] = useState(50) // Початкове значення

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const isValid = isPhoneValid(phone)

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const selectedValue = React.useMemo(
    () =>
      Array.from(selectedKeys)
        .map((key) => key.replace('_', ' '))
        .join(', '),
    [selectedKeys],
  )

  const updateMaxLength = () => {
    const width = window.innerWidth
    if (width <= 720) {
      setMaxLength(25)
    } else if (width <= 1440) {
      setMaxLength(50)
    } else {
      setMaxLength(100)
    }
  }

  useEffect(() => {
    updateMaxLength() // Встановити maxLength при першому рендері
    window.addEventListener('resize', updateMaxLength) // Додати слухача події

    return () => {
      window.removeEventListener('resize', updateMaxLength) // Прибрати слухача при демонтировании компонента
    }
  }, [])

  const close = () => {
    onClose()
    reset()
    setPhone('')
    setIsBlurredPhone(false)
  }
  const toastId = useRef(null)

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setPhone('')
      setIsBlurredPhone(false)
      onClose()
    }
    // eslint-disable-next-line
  }, [isSubmitSuccessful])

  const onSubmit = async ({ name, email, address, contactMethod }) => {
    toastId.current = toast(<Toastify type={'pending'} />, {
      autoClose: 3000,
      hideProgressBar: true,
    })

    const data = {
      name,
      phone,
      email,
      comment: `Address - ${address} \n Contact method - ${contactMethod} \n Services - ${Array.from(
        selectedKeys,
      ).join(', ')} `,
      to: toEmail,
    }

    if (name && !errors.email) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })

        if (response.ok) {
          toast.update(toastId.current, {
            render: <Toastify type={'success'} />,
            type: 'success',
            icon: false,
            autoClose: 3000,
            hideProgressBar: false,
          })
        } else {
          toast.update(toastId.current, {
            render:
              'Something went wrong. Please refresh the page and try again',
            type: 'error',
            autoClose: 7000,
          })
        }

        const responseData = await response.json()
        console.log('Success:', responseData)
        reset()
        setPhone('')
        console.log(data)
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  const redBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)',
  }
  const greenBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)',
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className={`${styles.myModal}`}
        hideCloseButton={true}
        placement={'center'}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <div className={styles.form_top}>
                  <div className={styles.form_top__first}>
                    <h2 className={styles.form_top__first_title}>{title}</h2>
                    <img
                      className={styles.form_top__first_img}
                      onClick={close}
                      src={closeBtn}
                      alt="close"
                    />
                  </div>
                </div>

                <div className={styles.form_bottom}>
                  <div className={styles.form_bottom__box}>
                    <label
                      htmlFor="name"
                      className={styles.form_bottom__box_lbl}
                    >
                      Full Name
                    </label>
                    <div
                      className={styles.form_bottom__box_wrap}
                      style={
                        errors?.name && touchedFields?.name
                          ? redBord
                          : !errors?.name && touchedFields?.name
                          ? greenBord
                          : null
                      }
                    >
                      <img
                        className={styles.form_bottom__box_wrap__img}
                        src={iconName}
                        alt="people"
                      />
                      <input
                        type="text"
                        id={'name'}
                        placeholder={'Enter your name'}
                        className={styles.form_bottom__box_wrap__inp}
                        {...register('name', {
                          required: true,
                          minLength: 2,
                          maxLength: 30,
                        })}
                        autoComplete={'off'}
                      />
                    </div>
                  </div>
                  <div className={styles.form_bottom__box}>
                    <label
                      htmlFor="Email"
                      className={styles.form_bottom__box_lbl}
                    >
                      Email Address
                    </label>
                    <div
                      className={styles.form_bottom__box_wrap}
                      style={
                        errors?.email && touchedFields?.email
                          ? redBord
                          : !errors?.email && touchedFields?.email
                          ? greenBord
                          : null
                      }
                    >
                      <img
                        className={styles.form_bottom__box_wrap__img}
                        src={iconMail}
                        alt="people"
                      />
                      <input
                        type="text"
                        id={'Email'}
                        placeholder={'Enter your Email address'}
                        className={styles.form_bottom__box_wrap__inp}
                        {...register('email', {
                          required: true,
                          minLength: 2,
                          maxLength: 50,
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className={styles.form_bottom__box}>
                    <label
                      htmlFor="Phone"
                      className={styles.form_bottom__box_lbl}
                    >
                      Phone Number
                    </label>
                    <div
                      className={styles.form_bottom__box_wrap}
                      style={
                        !isValid && isBlurredPhone
                          ? redBord
                          : isValid && isBlurredPhone
                          ? greenBord
                          : null
                      }
                    >
                      <PhoneInput
                        defaultCountry="usa"
                        className={styles.form_bottom__box_wrap__telInp}
                        onChange={(e) => {
                          setPhone(e)
                        }}
                        value={phone}
                        onBlur={() => {
                          setIsBlurredPhone(true)
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.form_bottom__box}>
                    <label
                      htmlFor="address"
                      className={styles.form_bottom__box_lbl}
                    >
                      Property Address
                    </label>
                    <div
                      className={styles.form_bottom__box_wrap}
                      style={
                        errors?.address && touchedFields?.address
                          ? redBord
                          : !errors?.address && touchedFields?.address
                          ? greenBord
                          : null
                      }
                    >
                      <img
                        className={styles.form_bottom__box_wrap__img}
                        src={iconMark}
                        alt="mark"
                      />
                      <input
                        type="text"
                        id={'address'}
                        placeholder={'Enter your property address'}
                        className={styles.form_bottom__box_wrap__inp}
                        {...register('address', {
                          required: true,
                          minLength: 2,
                          maxLength: 30,
                        })}
                        autoComplete={'off'}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.dropdown}>
                      <span className={styles.dropdown_label}>
                        Service of Interest
                      </span>
                      <Dropdown>
                        <DropdownTrigger className={styles.dropdown_select}>
                          <Button
                            variant="bordered"
                            className={`${styles.dropdown} ${styles.dropdown_bg}`}
                          >
                            {selectedKeys.size === 0
                              ? 'Select Service'
                              : truncateText(selectedValue, maxLength)}
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Multiple selection example"
                          variant="flat"
                          closeOnSelect={false}
                          selectionMode="multiple"
                          selectedKeys={selectedKeys}
                          onSelectionChange={setSelectedKeys}
                        >
                          <DropdownItem key="Siding">Siding</DropdownItem>
                          <DropdownItem key="Windows and Doors">
                            Windows and Doors
                          </DropdownItem>
                          <DropdownItem key="Development/Investments">
                            Development/Investments
                          </DropdownItem>
                          <DropdownItem key="Other">Other</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>

                  <Controller
                    name="contactMethod"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        label="Preferred Contact Method"
                        orientation="horizontal"
                        classNames={{
                          label: `${styles.input}`,
                          input: `${styles.input_bg}`,
                          inputWrapper: `${styles.input_bg}`,
                        }}
                      >
                        <Radio
                          classNames={{
                            label: `${styles.input}`,
                            input: `${styles.input_bg}`,
                            inputWrapper: `${styles.input_bg}`,
                          }}
                          value="phone"
                          color="warning"
                        >
                          Phone
                        </Radio>
                        <Radio
                          classNames={{
                            label: `${styles.input}`,
                            input: `${styles.input_bg}`,
                            inputWrapper: `${styles.input_bg}`,
                          }}
                          value="email"
                          color="warning"
                        >
                          Email
                        </Radio>
                      </RadioGroup>
                    )}
                  />
                </div>

                <div className={styles.form_btns}>
                  <button className={styles.form_btns__btn} type={'submit'}>
                    Send
                  </button>
                </div>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
})

export { PopUp }
