import './App.css'
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LandingPage from './Pages/LandingPage/LandingPage'
import SidingPage from './Pages/SidingPage/SidingPage'
import Header from './Modules/Header/Header'
import Footer from './Modules/Footer/Footer'
import Repairs from './Pages/Repairs/Repairs'
import ScrollToTop from './ScrollToTop'
import Developments from './Pages/DevelopmentsPage/Developments'
import WindowsPage from './Pages/WindowsPage/WindowsPage'
import OfferButton from './Components/OfferButton/OfferButton'

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/siding" exact element={<SidingPage />} />
          <Route path="/repairs" exact element={<Repairs />} />
          <Route path="/developments" exact element={<Developments />} />
          <Route path="/windows" exact element={<WindowsPage />} />
        </Routes>
        <Footer />
        <OfferButton />
      </Router>
    </div>
  )
}

export default App
